<template>
    <v-dialog v-model="dialog" width="300" persistent>
        <v-form v-model="valid">
        <v-card>
            <v-card-title class="d-flex justify-center">{{ $t('delete')}} {{ details.title }}</v-card-title>
            <h6 class="mx-4">Write the {{ details.subtitle }} of the {{ details.title }} that you want to delete.</h6>
            <v-text-field v-model="checkBeforeDelete" class="ma-4" outlined dense :label="details.fieldName" :rules="deleteRules">

            </v-text-field>
            <v-card-actions class="d-flex">
                <v-spacer />
                <v-btn small text @click="$emit('close')">
                    {{ $t('cancel')}}
                </v-btn>
                <v-btn :disabled="!valid" class="white--text" small color="red" @click="deleteObject">
                    {{ $t('delete')}}
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
export default {
    data () {
        return {
            valid: false,
            checkBeforeDelete: ''
        }
    },
    computed: {
        deleteRules(){
            if(this.checkBeforeDelete !== this.details.valueToCheck) {
                return ["Please write the correct medical team name!"]
            }
            return []
        },
    },
    props: ['dialog', 'details', 'item'],
    methods: {
        async deleteObject() {
            const body = this.details.body
            await this.$store.dispatch(this.details.functionName, body).then(() => {
                if(this.details.redirect){
                    this.$router.push({name: this.details.pathName})
                }else this.$emit('false')
            })
        },
    }
}
</script>