<template>
  <v-dialog v-model="dialog" width="20%" persistent>
    <v-form v-model="valid">
      <v-card flat style="overflow: hidden">
        <div class="d-flex">
          <v-spacer />
          <v-icon
            class="d-flex align-right justify-center ma-4"
            color="primary"
            @click="$emit('close')"
            >mdi-window-close</v-icon
          >
        </div>
        <span
          style="font-family: 'Catamaran', serif !important"
          class="d-flex text-h6 lighten-2 justify-center"
          >{{ $t("add") }} {{ $t("publicLink") }}</span
        >
        <div class="pa-8">
          <v-select
            v-model="publicLinkType"
            :items="publicLinkTypes"
            item-text="shortDescription"
            item-value="lowValue"
            label="Link Type"
            hide-details
            single-line
            class="height: 40px"
            filled
            rounded
            dense
            depressed
            style="
              background-color: #eceff7;
              border: none !important;
              align-items: center;
              display: flex;
              position: relative;
              box-shadow: none;
              border-bottom: none;
              border-radius: 12px !important;
              margin-bottom: 10px;
              font-size: 14px !important;
              font-weight: bold;
              color: #575ce5 !important;
            "
          />
          <v-text-field
            v-model="linkDescription"
            append-icon="mdi-text"
            label="Link Description"
            hide-details
            single-line
            class="height: 40px"
            filled
            rounded
            dense
            depressed
            style="
              background-color: #eceff7;
              border: none !important;
              align-items: center;
              display: flex;
              position: relative;
              box-shadow: none;
              border-bottom: none;
              border-radius: 12px !important;
              margin-bottom: 10px;
              font-size: 14px !important;
              font-weight: bold;
              color: #575ce5 !important;
            "
          ></v-text-field>
          <v-text-field
            v-model="expirationDate"
            :disabled="publicLinkType === 2 ? false : true"
            label="Expiration Date"
            hide-details
            type="date"
            single-line
            :min="nowDate"
            class="height: 40px"
            filled
            rounded
            dense
            depressed
            style="
              background-color: #eceff7;
              border: none !important;
              align-items: center;
              display: flex;
              position: relative;
              box-shadow: none;
              border-bottom: none;
              border-radius: 12px !important;
              margin-bottom: 10px;
              font-size: 14px !important;
              font-weight: bold;
              color: #575ce5 !important;
            "
          ></v-text-field>
        </div>
        <v-card-actions>
          <v-spacer />
          <div>
            <v-btn class="mx-2" color="red" text @click="$emit('close')">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              class="mx-2"
              color="primary"
              :disabled="!valid"
              @click="addPubliLink"
            >
              {{ $t("save") }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["dialog"],
  computed: {
    ...mapState({
      userDetails: (state) => state.authentication.userData,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
    }),
  },
  data() {
    return {
      valid: false,
      linkDescription: "",
      expirationDate: "",
      publicLinkType: -1,
      publicLinkTypes: [],
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  async mounted() {
    await this.getPublicLinkVerifiers();
  },
  methods: {
    async getPublicLinkVerifiers() {
      let header = {
        domainValue: "PUBLIC_LINK_TYPE",
      };
      await this.$store
        .dispatch("verifiers/getVerifiersForDomain", header)
        .then(() => {
          this.publicLinkTypes = this.verifiersFromRepo;
        });
    },
    async addPubliLink() {
      var body = {};
      if (this.publicLinkType === 2) {
        body = {
          patientId: this.userDetails.id,
          publicLinkType: this.publicLinkType,
          expirationDate: this.expirationDate,
          linkDescription: this.linkDescription,
        };
      } else {
        body = {
          patientId: this.userDetails.id,
          publicLinkType: this.publicLinkType,
          linkDescription: this.linkDescription,
        };
      }
      await this.$store
        .dispatch("publiclinks/createPublicLink", body)
        .then(() => {
          this.$emit("false");
        });
    },
  },
};
</script>