<template>
  <v-container>
    <v-progress-circular v-if="loading" indeterminate color="primary" />
    <div v-else>
      <v-row>
        <v-spacer />
        <v-btn
          class="mx-4 backgroundColor: primary"
          large
          icon
          @click="addPublicLinkDialog = true"
        >
          <v-icon color="white"> mdi-plus </v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, i) in publicLinks"
          :key="i"
          cols="12"
          xl="2"
          lg="3"
          md="4"
          sm="6"
        >
          <v-card outlined class="mx-auto" height="100%" rounded="xl">
            <div>
              <div class="d-flex">
                <v-card-text
                  class="d-flex align-left font-weight-bold my-n3"
                  style="color: #212121; font-size: 16px"
                  >{{ item.linkDescription }}</v-card-text
                >
                <v-icon class="mx-1" style="font-size: 20px" @click="shareLink">mdi-share</v-icon>
                <v-icon class="mr-1" style="font-size: 20px" @click="editLink">mdi-pencil</v-icon>
                <v-icon class="mr-2" style="font-size: 20px" @click="deleteLink(item)"
                  >mdi-delete</v-icon
                >
              </div>
              <v-card-text
                class="d-flex align-left font-weight-bold my-n3"
                style="color: #575ce5"
                >{{ item.publicLinkType === 3 ? item.publicLinkTypeDescription : item.publicLinkTypeLongDescription }}</v-card-text
              >
              <v-card-subtitle
                v-if="item.publicLinkType !== 2"
                class="d-flex align-left"
                style="color: grey"
                v-html="'No expiration date!'"
              ></v-card-subtitle>
              <v-card-subtitle
                v-if="item.publicLinkType === 2"
                class="d-flex align-left"
                style="color: grey"
                v-html="'Expires on: '+item.expirationDate.split('T')[0]"
              ></v-card-subtitle>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <add-public-link-dialog
      v-if="addPublicLinkDialog"
      :dialog="addPublicLinkDialog"
      @false="closePublicLinkDialog"
      @close="addPublicLinkDialog = false"
    />
    <delete-dialog v-if="deleteDialog" :dialog="deleteDialog" :details="deleteDetails" @false="closeDialog" @close="deleteDialog = false"/>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import AddPublicLinkDialog from "./AddPublicLinkDialog.vue";
import DeleteDialog from './DeleteDialog.vue';
export default {
  components: { AddPublicLinkDialog, DeleteDialog },
  data() {
    return {
      addPublicLinkDialog: false,
      loading: true,
      publicLinks: [],
      deleteDialog: false,
      deleteDetails: []
    };
  },
  computed: {
    ...mapState({
      publicLinksFromRepo: (state) => state.publiclinks.publicLinks,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
    }),
  },
  methods: {
    async closeDialog() {
      await this.getVerifiers();
      await this.getPublicLinks();
      this.deleteDialog = false
    },
    shareLink() {},
    editLink() {},
    deleteLink(item) {
        this.deleteDialog = true
        this.deleteDetails = {title: 'public link', subtitle: 'link description', fieldName: 'Link Description *', valueToCheck: item.linkDescription, functionName: 'publiclinks/deletePublicLink', body: {id: item.id}}
    },
    async closePublicLinkDialog() {
      this.addPublicLinkDialog = false;
      await this.getVerifiers();
      await this.getPublicLinks();
    },
    async getVerifiers() {
      let header = {
        domainValue: "PUBLIC_LINK_TYPE",
      };
      await this.$store.dispatch("verifiers/getVerifiersForDomain", header);
    },
    async getPublicLinks() {
      await this.$store.dispatch("publiclinks/getPublicLinks").then(() => {
        this.publicLinks = this.publicLinksFromRepo;
        this.publicLinks.forEach((pl) => {
          this.verifiersFromRepo.forEach((v) => {
            if (pl.publicLinkType === v.lowValue) {
              pl.publicLinkTypeDescription = v.shortDescription;
              pl.publicLinkTypeLongDescription = v.longDescription;
            }
          });
        });
        this.loading = false;
      });
    },
  },
  async mounted() {
    await this.getVerifiers();
    await this.getPublicLinks();
  },
};
</script>